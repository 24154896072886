<template>
    <div class="container">
        <div class="row">
            <div class="col col-3">
                <div
                    v-for="availableFacility in allFacilities"
                    :key="availableFacility.locationId"
                >
                    <mercur-checkbox
                        v-model="selectedFacilityIds"
                        :value="availableFacility.locationId"
                    >
                        {{availableFacility.locationName}}
                    </mercur-checkbox>
                </div>
            </div>
            <div class="col col-9">
                <div
                    v-for="(selectedFacility, key) in selectedFacilities"
                    :key="key"
                    class="facility-details"
                >
                    <h2 class="facility-title font-weight-normal">{{selectedFacility.locationName}}</h2>

                    <h4 class="font-weight-normal">Working days</h4>
                    <span
                        v-for="(dayOfWeek, index) in daysOfWeek"
                        :key="index">
                        <mercur-checkbox
                            class="mr-4"
                            v-model="selectedFacility.daysOfWeek"
                            :value="index"
                        >{{dayOfWeek}}</mercur-checkbox>
                    </span>
                    <hr />
                    <production-days
                        v-if="showProductionDaysAndQuantityFields"
                        v-model="selectedFacility.productionDays"
                        :default-cutoff-time="getFirstCutoffTime(selectedFacility)"
                    ></production-days>
                    <hr />
                    <div class="row">
                        <div class="col col-7">
                            <div v-if="allFacilitiesReference[selectedFacility.locationId].serviceLevels">
                                <h4 class="font-weight-normal">Service levels</h4>
                                <div class="servicelevel-checkboxes" v-if="allFacilitiesReference[selectedFacility.locationId].serviceLevels && allFacilitiesReference[selectedFacility.locationId].serviceLevels.length">
                                    <div v-for="(serviceLevel, serviceLevelKey) in allFacilitiesReference[selectedFacility.locationId].serviceLevels" :key="serviceLevelKey">
                                        <mercur-checkbox
                                            v-model="selectedFacilities[key].serviceLevelIds"
                                            :disabled="!serviceLevel.countriesTo"
                                            :value="serviceLevel.serviceLevelId"
                                            @change="handleChangeServiceLevels($event, selectedFacility.locationId)"
                                            class="servicelevel-checkbox mb-2"
                                            :data-test="`supplierProductImportServiceLevel${serviceLevelKey}`"
                                        >
                                            {{serviceLevel.serviceLevelName ? serviceLevel.serviceLevelName : serviceLevel.serviceLevelId}}
                                            <small v-if="serviceLevel.countriesTo && serviceLevel.countriesTo.length">
                                                (ships to: {{Object.values(serviceLevel.countriesTo || {}).join(', ')}})
                                            </small>
                                            <small class="error" v-else>
                                                (service level doesn't ship from {{ selectedFacility.country }})
                                            </small>
                                        </mercur-checkbox>
                                    </div>
                                    <span
                                        class="error"
                                        v-if="$v.selectedFacilities.$each[key] && !$v.selectedFacilities.$each[key].serviceLevels.hasItems"
                                    >
                                        At least one service level is required
                                    </span>
                                </div>
                                <div v-else class="servicelevels-status">
                                    <em class="error">No carrier service levels are set for this facility. You need to add a service level to this facility first.</em>
                                    <br />
                                    <router-link :to="getFacilityEditRoute(selectedFacility)">Go to facility</router-link>
                                </div>
                            </div>
                        </div>
                        <div class="col col-5">
                            <h4 class="font-weight-normal mb-0">Artwork preferences</h4>
                            <div class="servicelevel-checkboxes" v-if="selectedFacilities[key].artworkFilePreferences">
                                <div class="d-flex pt-2">
                                    <mercur-radio
                                        v-for="(fileFormat, fileFormatKey) in artworkFilePreferenceOptions.fileFormats"
                                        :key="fileFormatKey"
                                        v-model="selectedFacilities[key].artworkFilePreferences.image.format"
                                        :value="fileFormat.value"
                                        class="mr-20"
                                    >
                                        {{ fileFormat.title }}
                                    </mercur-radio>
                                </div>
                                <div class="mt-5 pb-10">
                                    <mercur-checkbox class="mb-0" v-model="selectedFacilities[key].artworkFilePreferences.mockup.required">
                                        Mockup required
                                    </mercur-checkbox>
                                    <div class="pl-35 pt-2 d-flex" v-if="selectedFacilities[key].artworkFilePreferences.mockup.required">
                                        <mercur-radio
                                            v-for="(fileFormat, fileFormatKey) in artworkFilePreferenceOptions.fileFormats"
                                            :key="fileFormatKey"
                                            v-model="selectedFacilities[key].artworkFilePreferences.mockup.format"
                                            :value="fileFormat.value"
                                            class="mr-20"
                                        >
                                            {{ fileFormat.title }}
                                        </mercur-radio>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CONFIG from '@root/config'
import ProductionDays from '@/components/ProductionDays'
export default {
    name: 'ProductFacilitiesSelector',
    components: { ProductionDays },
    props: {
        value: {
            default: null,
        },
        showProductionDaysAndQuantityFields: {
            default: true,
            required: false,
            type: Boolean,
        },
        autoSelectFirst: {
            default: false,
            type: Boolean,
        },
    },
    data () {
        return {
            allFacilities: null,
            allFacilitiesReference: null,
            facilities: null,
            selectedFacilities: [],
            facilityServiceLevels: {},
            daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            hasSyncedFacilities: false,
            selectedFacilitiesUnwatcher: null,
            availablePackages: null,
            artworkFilePreferenceOptions: {
                fileFormats: [
                    {
                        title: 'PDF',
                        value: 'PDF',
                    },
                    {
                        title: 'PNG',
                        value: 'PNG',
                    },
                ],
            },
        }
    },

    computed: {
        selectedFacilityIds: {
            get () {
                return this.selectedFacilities.map(facility => facility.locationId)
            },
            set (value) {
                this.$set(this, 'selectedFacilities', value.map(locationId => {
                    const selectedFacility = JSON.parse(JSON.stringify(this.allFacilities.find(availableFacility => availableFacility.locationId === locationId)))
                    if (!selectedFacility.serviceLevelIds) {
                        const currentlySelectedFacility = this.selectedFacilities.find(facility => facility.locationId === locationId)
                        if (currentlySelectedFacility) {
                            selectedFacility.serviceLevelIds = currentlySelectedFacility.serviceLevels.map(serviceLevel => serviceLevel.serviceLevelId)
                        }
                        if (!selectedFacility.serviceLevelIds) {
                            selectedFacility.serviceLevelIds = []
                        }
                    }
                    return selectedFacility
                }))
            },
        },
        packageConfigurationsViewRoute () {
            return {
                name: 'SupplierPackageConfigurations',
                params: {
                    supplierId: this.supplierId,
                },
            }
        },
    },

    validations: {
        packageConfigurations: {
            $each: CONFIG.VALIDATIONS.PACKAGES.PACKAGE_CONFIGURATION,
        },
        selectedFacilities: {
            $each: {
                serviceLevels: {
                    hasItems: (value) => {
                        return value.length > 0
                    },
                },
            },
        },
    },

    methods: {
        handleChangeServiceLevels (serviceLevelIds, facilityId) {
            const facility = this.allFacilities.find(facility => facility.locationId === facilityId)
            const selectedFacility = this.selectedFacilities.find(facility => facility.locationId === facilityId)
            this.$set(selectedFacility, 'serviceLevels', serviceLevelIds.map(serviceLevelId => facility.serviceLevels.find(serviceLevel => serviceLevel.serviceLevelId === serviceLevelId)).filter(item => item))
        },
        getAvailablePackagesForFacility (facility) {
            if (this.availablePackages === null) {
                return []
            }

            return this.availablePackages
        },
        selectPackage (availablePackage) {
            const newPackage = {
                ...availablePackage,
                packageType: null,
                maxWidth: null,
                weight: null,
                maxUnitsPerPackage: null,
                maxSurface: null,
                multiplier: 1,
            }
            this.$v.packageConfigurations.$model.unshift(newPackage)
        },
        getFirstCutoffTime (facility) {
            for (let i = 0; i < facility.workingDays.length; i++) {
                if (facility.workingDays[i] && facility.workingDays[i].cutOffTime) {
                    return facility.workingDays[i].cutOffTime
                }
            }
            return null
        },
        getFacilityEditRoute (facility) {
            return {
                name: 'SupplierEditFacility',
                params: {
                    supplierId: this.supplierId,
                    facilityId: facility.locationId,
                },
            }
        },
        getPackages () {
            const url = CONFIG.API.ROUTES.SUPPLIERS.PACKAGES_CONFIGURATION.OVERVIEW.replace('{supplierId}', this.supplierId)
            this.addJob(url)

            this.$api.get(url).then(({ data }) => {
                this.availablePackages = data.data.items
            }).catch(() => {
                this.$root.$emit('notification:global', {
                    message: `Getting packages failed. Please try again.`,
                    type: 'error',
                })
            }).finally(() => {
                this.finishJob(url)
            })
        },
        checkVerification () {
            this.$v.$touch()
            return this.$v
        },
        mapFacilityData (referenceSelectedFacility, newFacilityData) {
            const fieldsToOverwrite = ['address', 'addressAdditional', 'certifications', 'city', 'companyName', 'country', 'countryCode', 'emailAddress', 'facilitySettings',
                'financeSettings', 'firstName', 'houseNumber', 'houseNumberAdditional', 'lastName', 'locationName', 'locationStatus', 'locationType', 'phone',
                'source', 'sourceId', 'state']

            fieldsToOverwrite.forEach(field => {
                referenceSelectedFacility[field] = newFacilityData[field]
            })

            return referenceSelectedFacility
        },
    },

    created () {
        this.getPackages()
        const selectedFacilitiesPromise = new Promise((resolve, reject) => {
            this.$watch('value', (value) => {
                if (value) {
                    resolve(value)
                }
            }, {
                immediate: true,
            })
        })

        const allFacilitiesPromise = this.$store.dispatch('suppliers/fetchSupplierLocationsWithServiceLevelsWithCountries', this.supplierId)
        Promise.all([selectedFacilitiesPromise, allFacilitiesPromise]).then(([selectedFacilities, allFacilities]) => {
            this.$set(this, 'allFacilities', allFacilities)
            this.$set(this, 'allFacilitiesReference', Object.fromEntries(allFacilities.map(facility => [facility.locationId, facility])))

            this.selectedFacilities = selectedFacilities.map(selectedFacility => {
                if (!selectedFacility.serviceLevelIds) {
                    this.$set(selectedFacility, 'serviceLevelIds', selectedFacility.serviceLevels.map(serviceLevel => serviceLevel.serviceLevelId))
                }
                if (!selectedFacility.artworkFilePreferences) {
                    this.$set(selectedFacility, 'artworkFilePreferences', {})
                }
                if (!selectedFacility.artworkFilePreferences.image) {
                    this.$set(selectedFacility.artworkFilePreferences, 'image', {
                        required: true,
                        format: 'PDF',
                    })
                }
                if (!selectedFacility.artworkFilePreferences.mockup) {
                    this.$set(selectedFacility.artworkFilePreferences, 'mockup', {
                        required: false,
                        format: 'PDF',
                    })
                }
                return selectedFacility
            })

            this.$watch('selectedFacilities', function (value) {
                setTimeout(() => {
                    // enrich the selected facility with possible updated facility address details
                    const selectedFacilities = value.map(selectedFacility => {
                        const originalFacility = this.allFacilities.find(facility => facility.locationId === selectedFacility.locationId)
                        if (!selectedFacility.serviceLevelIds) {
                            selectedFacility.serviceLevelIds = []
                        }
                        return {
                            ...selectedFacility,
                            serviceLevels: selectedFacility.serviceLevelIds.map(serviceLevelId => originalFacility.serviceLevels.find(originalFacilityServiceLevel => originalFacilityServiceLevel.serviceLevelId === serviceLevelId)).filter(item => item),
                            address: originalFacility.address,
                            addressAdditional: originalFacility.addressAdditional,
                            city: originalFacility.city,
                            country: originalFacility.country,
                            countryCode: originalFacility.countryCode,
                            emailAddress: originalFacility.emailAddress,
                            houseNumber: originalFacility.houseNumber,
                            houseNumberAdditional: originalFacility.houseNumberAdditional,
                            zipCode: originalFacility.zipCode,
                            state: originalFacility.state,
                        }
                    })
                    this.$emit('input', selectedFacilities)
                }, 1000)
            }, {
                immediate: true,
                deep: true,
            })
        })
    },
}
</script>

<style lang="scss" scoped>
.facility-details {
    margin-bottom: 30px;
    border: 1px solid #dedede;
    padding: 0 10px 5px 10px;
    background-color: white;
}
.facility-title {
    border-top: 1px solid #dedede;
    border-bottom: 1px solid #dedede;
    background-color: white;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    margin-left: -10px;
    margin-right: -10px;
    position: sticky;
    top: -20px;
    margin-top: -1px;
    z-index: 100;
}
</style>
