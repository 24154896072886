import {
    helpers,
} from 'vuelidate/lib/validators'

export function lessThanOrEqualReference (lessThan) {
    return helpers.withParams({ type: 'sameAs', eq: lessThan }, function (value, parentVm) {
        return value <= helpers.ref(lessThan, this, parentVm)
    })
}
export function moreThanOrEqualReference (moreThan) {
    return helpers.withParams({ type: 'sameAs', eq: moreThan }, function (value, parentVm) {
        return value >= helpers.ref(moreThan, this, parentVm)
    })
}
