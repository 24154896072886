<template>
    <div class="production-days">
        <div>
            <h4 class="font-weight-normal mb-0">Production days and quantities</h4>
            <div class="production-days-list">
                <span v-if="localValue.length === 0" class="error">No production days set</span>
                <div v-for="(productionDaySet, key, index) in $v.localValue.$each.$iter" :key="key" class="production-day-set">
                    <mercur-button class="btn btn-icon production-day-set__remove" v-if="localValue.length > 1" @click="localValue.splice(key, 1)"><i class="fas fa-trash"></i></mercur-button>
                    <div class="row production-day-set__layout">
                        <div class="col col-2">
                            <mercur-input
                                :class="{'form-invalid': productionDaySet.days.$error}"
                                v-model.number="productionDaySet.days.$model"
                                type="number"
                                min="0"
                                required
                                :data-test="`addProductionDay${index}Days`"
                            >
                                <template v-if="index === 0">Number of days</template>
                                <span :data-test="`addProductionDay${index}DaysError`" slot="note" v-if="!productionDaySet.days.required" class="production-day-set__error-message">Production days is required</span>
                                <span :data-test="`addProductionDay${index}DaysError`" slot="note" v-if="!isUnique(productionDaySet)" class="production-day-set__error-message">Needs to be unique combination</span>
                            </mercur-input>
                        </div>
                        <div class="col col-4">
                            <mercur-input
                                :class="{'form-invalid': productionDaySet.from.$error && productionDaySet.to.$dirty}"
                                v-model.number="productionDaySet.from.$model"
                                type="number"
                                :min="productionDaySet.from.$params.minValue.min"
                                required
                                :data-test="`addProductionDay${index}From`"
                            >
                                <template v-if="index === 0">From quantity</template>
                                <span :data-test="`addProductionDay${index}FromError`" slot="note" v-if="!productionDaySet.from.required" class="production-day-set__error-message">From quantity is required</span>
                                <span :data-test="`addProductionDay${index}FromError`" slot="note" v-else-if="!productionDaySet.from.minValue" class="production-day-set__error-message">Minimum value is {{productionDaySet.from.$params.minValue.min}}</span>
                                <span :data-test="`addProductionDay${index}FromError`" slot="note" v-else-if="!productionDaySet.from.lessThanOrEqualReference" class="production-day-set__error-message">Should be less than or equal <em>To</em> value ({{productionDaySet.to.$model}})</span>
                                <span :data-test="`addProductionDay${index}FromError`" slot="note" v-else-if="!isUnique(productionDaySet)" class="production-day-set__error-message">Needs to be unique combination</span>
                            </mercur-input>
                        </div>
                        <div class="col col-4">
                            <mercur-input
                                :class="{'form-invalid': productionDaySet.to.$error}"
                                v-model.number="productionDaySet.to.$model"
                                type="number" :min="productionDaySet.to.$params.minValue.min"
                                required
                                :data-test="`addProductionDay${index}To`"
                            >
                                <template v-if="index === 0">To quantity</template>
                                <span :data-test="`addProductionDay${index}ToError`" slot="note" v-if="!productionDaySet.to.required" class="production-day-set__error-message" >To quantity is required</span>
                                <span :data-test="`addProductionDay${index}ToError`" slot="note" v-else-if="!productionDaySet.to.minValue" class="production-day-set__error-message" >Minimum value is {{productionDaySet.to.$params.minValue.min}}</span>
                                <span :data-test="`addProductionDay${index}ToError`" slot="note" v-else-if="!productionDaySet.to.moreThanOrEqualReference" class="production-day-set__error-message">Must be equal or more than <em>From</em> value ({{productionDaySet.from.$model}})</span>
                                <span :data-test="`addProductionDay${index}ToError`" slot="note" v-if="!isUnique(productionDaySet)" class="production-day-set__error-message">Needs to be unique combination</span>
                            </mercur-input>
                        </div>
                        <div class="col col-2">
                            <mercur-input
                                v-model.number="productionDaySet.steps.$model"
                                type="number"
                                step="1"
                                :data-test="`addProductionDay${index}Steps`"
                            >
                                <template v-if="index === 0">Number of steps</template>
                                <span :data-test="`addProductionDay${index}StepsError`" slot="note" v-if="!isUnique(productionDaySet)" class="production-day-set__error-message">Needs to be unique combination</span>
                            </mercur-input>
                        </div>
                    </div>
                </div>
            </div>

            <mercur-button
                class="btn btn-icon btn-yellow btn-icon-sm"
                @click="addProductionDay"
                data-test="addProductionDay"
            ><i class="fas fa-plus"></i></mercur-button>
        </div>
        <hr />
    </div>
</template>
<script>
import { validationMixin } from 'vuelidate'
import {
    required,
    minLength,
    minValue,
} from 'vuelidate/lib/validators'
import {
    lessThanOrEqualReference,
    moreThanOrEqualReference,
} from '@/components/utils/ValidationUtils'

export default {
    name: 'ProductionDays',
    mixins: { validationMixin },
    props: ['value', 'defaultCutoffTime'],
    data () {
        return {
            localValue: this.value || [],
        }
    },
    validations: {
        localValue: {
            required,
            minLength: minLength(1),
            $each: {
                days: {
                    required,
                    unique: function (value, vm) {
                        return this.checkIfUnique(vm)
                    },
                },
                steps: {
                    unique: function (value, vm) {
                        return this.checkIfUnique(vm)
                    },
                },
                from: {
                    required,
                    minValue: minValue(1),
                    lessThanOrEqualReference: lessThanOrEqualReference('to'),
                    unique: function (value, vm) {
                        return this.checkIfUnique(vm)
                    },
                },
                to: {
                    required,
                    minValue: minValue(1),
                    moreThanOrEqualReference: moreThanOrEqualReference('from'),
                    unique: function (value, vm) {
                        return this.checkIfUnique(vm)
                    },
                },
            },
        },
    },
    watch: {
        localValue: {
            handler (newValue) {
                if (this.$v.$invalid) {
                    this.$emit('input', null)
                    return
                }
                this.$emit('input', newValue)
            },
            deep: true,
        },
    },
    methods: {
        addProductionDay () {
            this.localValue.push({
                days: null,
                steps: null,
                from: null,
                to: null,
            })
        },
        isUnique (productionDays) {
            return productionDays.days.unique && productionDays.from.unique && productionDays.to.unique && productionDays.steps.unique
        },
        checkIfUnique (vm) {
            const strings = this.localValue.map(e => JSON.stringify(e))
            const item = JSON.stringify(vm)
            return strings.filter(string => string === item).length < 2
        },
    },
}
</script>

<style lang="scss" scoped>
    .production-days {
        padding-bottom: 10px;
        padding-top: 10px;
        display: block;
    }

    .production-days-list {
        padding-bottom: 10px;
        padding-left: 10px;
        padding-top: 10px;
    }

    .production-day-set {
        background-color: #f9f9f9;
        border-left: 3px solid #dedede;
        margin-bottom: 10px;
        margin-top: 10px;
        padding-bottom: 1px;
        padding-left: 3px;
        position: relative;

        &__remove {
            left: -28px;
            opacity: 0.3;
            position: absolute;
            top: calc(50% - 18px);
            transform: scale(0.7);
        }

        &:hover .production-day-set__remove,
        .production-day-set__remove:focus {
            opacity: 1;
        }

        &__error-message {
            font-size: 10px;
            letter-spacing: 0;
            line-height: 0.9em;
            color: red;
        }
    }
</style>
